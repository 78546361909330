import * as React from "react"
import LegalLayout from "../components/legal-layout"
import PrivacyPolicy from "../components/privacy-policy"

import DefaultLayout from "../components/default-layout"
import Seo from "../components/seo"
import Layout from "../components/app-layout"

const PrivacyPolicyPage = () => (
  <Layout>
    <DefaultLayout>
      <LegalLayout>
        <PrivacyPolicy />
      </LegalLayout>
    </DefaultLayout>
  </Layout>
)

export const Head = () => <Seo title="Privacy Policy" />

export default PrivacyPolicyPage
