import * as React from "react"
import { Paragraph } from "./index.style"

const PrivacyPolicy = () => {
  return (
    <>
      <Paragraph>PRIVACY POLICY</Paragraph>

      <Paragraph>
        Welcome to the website (the “Site”) of 241 W. 28th St. Owner LLC (“28th
        Street Owner,” “we,” “us,” or “our”). 28th Street Owner is an entity of
        MAG Partners, a women-owned, urban real estate company with decades of
        experience developing impactful, iconic, large-scale projects. We have
        expertise in development, design, construction, leasing, property
        management, investment management, and financing (collectively,
        including the Site, the “Service”).
      </Paragraph>
      <Paragraph>
        This Privacy Policy explains what Personal Data (defined below) we
        collect, how we use and share that data, and your choices concerning our
        data practices.
      </Paragraph>
      <Paragraph>
        Before using the Service or submitting any Personal Data to 28th Street
        Owner, please review this Privacy Policy carefully and contact us if you
        have any questions. By using the Service, you agree to the practices
        described in this Privacy Policy. If you do not agree to this Privacy
        Policy, please do not access the Site or otherwise use the Service.
      </Paragraph>
      <Paragraph>1. PERSONAL DATA WE COLLECT</Paragraph>
      <Paragraph>
        We collect information that alone or in combination with other
        information in our possession could be used to identify you (“Personal
        Data”) as follows:
      </Paragraph>
      <Paragraph>
        Personal Data You Provide:
        <br />
        We collect Personal Data when you submit your contact information (e.g.,
        name, email address) for purposes of receiving information from us
        including, but not limited to, newsletters and other direct contact
        resulting from your request to be contacted.
      </Paragraph>
      <Paragraph>
        Personal Data We Receive Automatically From Your Use of the Site:
        <br />
        When you visit, use and interact with the Site, we may receive certain
        information about your visit, use or interactions. For example, we may
        monitor the number of people that visit the Site, peak hours of visits,
        which page(s) are visited, the domains our visitors come from (e.g.,
        google.com, yahoo.com, etc.), and which browsers people use to access
        the Service (e.g., Firefox, Microsoft Internet Explorer, etc.), broad
        geographical information, and navigation pattern. In particular, the
        following information is created and automatically logged in our
        systems:
      </Paragraph>
      <Paragraph>
        Log data: Information that your browser automatically sends whenever you
        visit the Site (“log data”). Log data includes your Internet Protocol
        address, browser type and settings, the date and time of your request,
        and how you interacted with the Site.
      </Paragraph>
      <Paragraph>
        Cookies: Please see the “Cookies” section below to learn more about how
        we use cookies.
      </Paragraph>
      <Paragraph>
        Device information: Includes name of the device, operating system, and
        browser you are using. Information collected may depend on the type of
        device you use and its settings. <br />
        Usage Information: We collect information about how you use our Site,
        such as the types of content that you view or engage with, the features
        you use, the actions you take, and the time, frequency and duration of
        your activities.
      </Paragraph>
      <Paragraph>
        Cookies:
        <br />
        We use cookies to operate and administer our Site, gather usage data on
        our Site and improve your experience on it. A “cookie” is a piece of
        information sent to your browser by a website you visit. Cookies can be
        stored on your computer for different periods of time. Some cookies
        expire after a certain amount of time, or upon logging out (session
        cookies), others survive after your browser is closed until a defined
        expiration date set in the cookie (as determined by the third party
        placing it), and help recognize your computer when you open your browser
        and browse the Internet again (persistent cookies). For more details on
        cookies please visit All About Cookies.
      </Paragraph>
      <Paragraph>
        Analytics:
        <br />
        We use Google Analytics, a web analytics service provided by Google,
        Inc. (“Google”). Google Analytics uses cookies to help us analyze how
        users use the Site and enhance your experience when you use the Site.
        For more information on how Google uses this data, go to
        www.google.com/policies/privacy/partners/.
      </Paragraph>
      <Paragraph>
        Personal Data We Collect Through Our Social Media Pages:
        <br />
        We have pages on social media sites like Facebook, Instagram, Pinterest,
        Twitter, and LinkedIn (“Social Media Pages”). When you interact with our
        Social Media Pages, we will collect Personal Data that you elect to
        provide to us, such as your contact details. In addition, the companies
        that host our Social Media Pages may provide us with aggregate
        information and analytics regarding the use of our Social Media Pages.
      </Paragraph>
      <Paragraph>
        Online Tracking and Do Not Track Signals:
        <br />
        We and our third party service providers may use cookies or other
        tracking technologies to collect information about your browsing
        activities over time and across different websites following your use of
        the Site. Our Site currently does not respond to “Do Not Track” (“DNT”)
        signals and operates as described in this Privacy Policy whether or not
        a DNT signal is received. If we do respond to DNT signals in the future,
        we will update this Privacy Policy to describe how we do so.
      </Paragraph>
      <Paragraph>
        Your Choices:
        <br />
        On most web browsers, you will find a “help” section on the toolbar.
        Please refer to this section for information on how to receive a
        notification when you are receiving a new cookie and how to turn cookies
        off. Please see the links below for guidance on how to modify your web
        browser’s settings on the most popular browsers:
        <br />
        Internet Explorer
        <br />
        Mozilla Firefox
        <br />
        Google Chrome
        <br />
        Apple Safari
        <br />
        Please note that if you limit the ability of websites to set cookies,
        you may be unable to access certain parts of the Site and you may not be
        able to benefit from the full functionality of the Site. Advertising
        networks may collect Personal Data through the use of cookies. Most
        advertising networks offer you a way to opt out of targeted advertising.
        If you would like to find out more information, please visit the Network
        Advertising Initiative’s online resources at
        http://www.networkadvertising.org/ and follow the opt-out instructions
        there. If you access the Site on your mobile device, you may not be able
        to control tracking technologies through the settings.
      </Paragraph>
      <Paragraph>2. HOW WE USE PERSONAL DATA:</Paragraph>
      <Paragraph>
        We may use Personal Data for the following purposes:
        <br />
        To provide the Service;
        <br />
        To respond to your inquiries, comments, feedback or questions;
        <br />
        To send administrative information to you, for example, information
        regarding the Service, and changes to our terms, conditions, and
        policies;
        <br />
        To analyze how you interact with our Service;
        <br />
        To maintain and improve the Service, including content and functionality
        of the Service;
        <br />
        To develop new products and services;
        <br />
        To prevent fraud, criminal activity, or misuses of our Service, and to
        ensure the security of our IT systems, architecture and networks; and To
        comply with legal obligations and legal process and to protect our
        rights, privacy, safety or property, and/or that of our affiliates, you
        or other third parties.
      </Paragraph>
      <Paragraph>
        Aggregated Information:
        <br />
        We may aggregate Personal Data and use the aggregated information to
        analyze the effectiveness of our Service, to improve and add features to
        our Service, and for other similar purposes. In addition, from time to
        time, we may analyze the general behavior and characteristics of users
        of our Services and share aggregated information like general user
        statistics with prospective business partners. We may collect aggregated
        information through the Service, through cookies, and through other
        means described in this Privacy Policy.
      </Paragraph>
      <Paragraph>
        Marketing:
        <br />
        We may use your Personal Data to contact you to tell you about products
        or services we believe may be of interest to you. For instance, if you
        elect to provide your email or telephone number, we may use that
        information to send you special offers. You may opt out of receiving
        emails by following the instructions contained in each promotional email
        we send you. In addition, if at any time you do not wish to receive
        future marketing communications, you may contact us. If you unsubscribe
        from our marketing lists, you will no longer receive marketing
        communications but we will continue to contact you regarding management
        of your account, other administrative matters, and to respond to your
        requests.
      </Paragraph>
      <Paragraph>3. SHARING AND DISCLOSURE OF PERSONAL DATA</Paragraph>
      <Paragraph>
        In certain circumstances we may share your Personal Data with third
        parties without further notice to you, unless required by the law, as
        set forth below: Vendors and Service Providers: To assist us in meeting
        business operations needs and to perform certain services and functions,
        we may share Personal Data with affiliates, vendors and service
        providers, including providers of hosting services, business
        intelligence tools, email newsletter services, payment processors, and
        web analytics services. Pursuant to our instructions, these parties will
        access, process or store Personal Data in the course of performing their
        duties to us. Business Transfers: If we are involved in a merger,
        acquisition, financing due diligence, reorganization, bankruptcy,
        receivership, sale of all or a portion of our assets, or transition of
        service to another provider, your Personal Data and other information
        may be shared in the diligence process with counterparties and others
        assisting with the transaction and transferred to a successor or
        affiliate as part of that transaction along with other assets.
      </Paragraph>
      <Paragraph>
        Legal Requirements: If required to do so by law or in the good faith
        belief that such action is necessary to (i) comply with a legal
        obligation, including to meet national security or law enforcement
        requirements, (ii) protect and defend our rights or property, (iii)
        prevent fraud, (iv) act in urgent circumstances to protect the personal
        safety of users of the Services, or the public, or (v) protect against
        legal liability.
      </Paragraph>
      <Paragraph>4. UPDATE YOUR INFORMATION</Paragraph>
      <Paragraph>
        Please contact us if you need to change or correct your Personal Data.
      </Paragraph>
      <Paragraph>5. CALIFORNIA PRIVACY RIGHTS DISCLOSURES</Paragraph>
      <Paragraph>
        Third Party Marketing: <br />
        If you are a California resident and wish to opt out of sharing your
        Personal Data with third parties for their direct marketing purposes,
        please email info@rubychelsea.com and clearly state your request,
        including your name, mailing address, email address and phone number.
        Please see the “Your Choices” section of this Privacy Policy for
        additional information.
      </Paragraph>
      <Paragraph>6. CHILDREN</Paragraph>
      <Paragraph>
        Our Service is not directed to children who are under the age of 13.
        28th Street Owner does not knowingly collect Personal Data from children
        under the age of 13. If you have reason to believe that a child under
        the age of 13 has provided Personal Data to 28th Street Owner through
        the Service please contact us and we will endeavor to delete that
        information from our databases.
      </Paragraph>
      <Paragraph>7. LINKS TO OTHER WEBSITES</Paragraph>
      <Paragraph>
        The Service may contain links to other websites not operated or
        controlled by 28th Street Owner, including social media services (“Third
        Party Sites”). The information that you share with Third Party Sites
        will be governed by the specific privacy policies and terms of service
        of the Third Party Sites and not by this Privacy Policy. By providing
        these links we do not imply that we endorse or have reviewed these
        sites. Please contact the Third Party Sites directly for information on
        their privacy practices and policies.
      </Paragraph>
      <Paragraph>8. SECURITY</Paragraph>
      <Paragraph>
        You use the Service at your own risk. We implement commercially
        reasonable technical, administrative, and organizational measures to
        protect Personal Data both online and offline from loss, misuse, and
        unauthorized access, disclosure, alteration or destruction. However, no
        Internet or e-mail transmission is ever fully secure or error free. In
        particular, e-mail sent to or from us may not be secure. Therefore, you
        should take special care in deciding what information you send to us via
        the Site or e-mail. Please keep this in mind when disclosing any
        Personal Data to 28th Street Owner via the Internet. In addition, we are
        not responsible for circumvention of any privacy settings or security
        measures contained on the Site or third party websites.
      </Paragraph>
      <Paragraph>9. YOUR CHOICES</Paragraph>
      <Paragraph>
        In certain circumstances providing Personal Data is optional. However,
        if you choose not to provide Personal Data that is needed to use some
        features of our Services, you may be unable to use those features. You
        can also contact us to ask us to update or correct your Personal Data.
      </Paragraph>
      <Paragraph>10. CHANGES TO THE PRIVACY POLICY</Paragraph>
      <Paragraph>
        The Service and our business may change from time to time. As a result
        we may change this Privacy Policy at any time. When we do we will post
        an updated version on this page, unless another type of notice is
        required by the applicable law. By continuing to use our Service or
        providing us with Personal Data after we have posted an updated Privacy
        Policy, or notified you by other means if applicable, you consent to the
        revised Privacy Policy and practices described in it.
      </Paragraph>
      <Paragraph>11. CONTACT US</Paragraph>
      <Paragraph>
        If you have any questions about our Privacy Policy or information
        practices, please feel free to contact us at info@rubychelsea.com.
      </Paragraph>

    </>
  )
}

export default PrivacyPolicy
